import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import styles from "./HospitalList.module.scss";
import { isMobile } from "react-device-detect";
import rightArrow from "../../assets/rightArrow.png";
import hos1 from "../../assets/hos1.jpeg";
import hos2 from "../../assets/hos2.png";
import hos3 from "../../assets/hos3.jpeg";
import { useState } from "react";

function HospitalList() {
  const navigate = useNavigate();
  const { programId } = useParams();

  const [hospitalList, setHospitalList] = useState([
    {
      title: "마디로 한의원 선릉점",
      tag1: "야간진료",
      tag2: "주말진료",
      site: "1",
      img: hos1,
    },
    {
      title: "병인한의원",
      tag1: "야간진료",
      tag2: "주말진료",
      site: "10022",
      img: hos2,
    },
    {
      title: "다시올한의원",
      tag1: "야간진료",
      tag2: "주말진료",
      site: "10021",
      img: hos3,
    },
  ]);
  return (
    <>
      <Header />
      <div className={styles.categorySelectWrap}>
        <h3>병원진료</h3>
        <ul
          className={`${styles.selectProgramListWrap} ${
            isMobile ? styles.isMobile : ""
          }`}
        >
          {hospitalList &&
            hospitalList.map((hospital, idx) => (
              <li
                key={idx}
                onClick={() => {
                  navigate(`/productDetail/${hospital.site}`);
                }}
              >
                <div className={styles.hospitalLeft}>
                  <img src={hospital.img} alt="hospitalLogo" />
                  <div className={styles.hospitalInfo}>
                    <p>{hospital.title}</p>
                    <div className={styles.hospitalTag}>
                      <span>{hospital.tag1}</span>
                      <span>{hospital.tag2}</span>
                    </div>
                  </div>
                  {/* <p>서울 강남구 테헤란로64길 8 지하1층</p> */}
                </div>
                <img src={rightArrow} alt="direct" />
              </li>
            ))}
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default HospitalList;
